@import "../../variables.scss";

.section {
  width: 100%;
  height: 100%;
  background: #00a4ea url(/assets/images/common/bg_03.png) no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_field {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);

  > div {
    background-color: #ff4c6a;

    p {
      padding: 11px 18px 10px 43px;
      font-size: 1.5rem;
      line-height: 1.5;
      color: #fff;
      white-space: nowrap;
      background: url(/assets/images/common/error_01.svg) no-repeat 16px center;
      background-size: 20px auto;

      margin: 0;

      opacity: 0;
      transform: translateX(100%);
      animation: slideIn 0.2s ease-in-out forwards;
    }
  }

  > form {
    width: 100%;
    padding: 18px 16px;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
    transform: translateX(0);
  }
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -85px;
  margin-top: -100px;
  background: url(logo_01.svg) no-repeat center center;
  background-size: 150px auto;
  width: 170px;
  height: 113px;
}

.glb_btn01 {
  width: 100%;
  display: inline-block;
  background: #17a1e6;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 14px 24px 13px;
  border-radius: 5px;
  border: 1px solid #17a1e6;
  opacity: 1;
  transition: opacity 0.1s linear;
  text-align: center;
  cursor: pointer;
  &.is-disable {
    cursor: default;
    opacity: 0.6;
  }
}

@media screen and (min-width: $mediaW) {
  .section {
    background-image: url(/assets/images/common/bg_04.png);
  }
  .logo {
    margin: 0;
    top: 18px;
    left: 16px;
    background: url(logo_02.svg) no-repeat;
    width: 192px;
    height: 48px;
  }
  .form_field {
    width: 400px;
    top: 50%;
    left: 50%;
    margin-top: -93px;
    margin-left: -200px;
    border-radius: 5px;
    bottom: auto;
    > div {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}
