@charset "utf-8";

// 01.SETTING

// ===== VARIABLE ===============================

// Directory Path
$imgPath: "../images";

// Font Size

// Font Family
$fontFamily: "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ヒラギノ角ゴ Pro W3",
  "ＭＳ Ｐゴシック", Arial, "Helvetica Neue", Helvetica, sans-serif;
$fontFamily2: Arial, "Helvetica Neue", Helvetica, "Hiragino Kaku Gothic Pro",
  メイリオ, Meiryo, "ヒラギノ角ゴ Pro W3", "ＭＳ Ｐゴシック", sans-serif;

// Color
$colorText: #333333;
$colorLink: #333333;

// Height
$headerH: 50px;
$headerH02: 60px;
$bottomP: 100px;

// MediaQuery
$mediaW: 1025px;

// ===== EXTEND =================================

// ===== MIXIN ==================================

// Clearfix
@mixin clearfix {
  &:after {
    content: "";
    height: 0;
    display: block;
    clear: both;
  }
}

// sidePadding
@mixin sidePadding {
  padding-left: 15px;
  padding-right: 15px;
}

// Scrollbar Style
@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    background: #e6e6e6;
  }
  &::-webkit-scrollbar-thumb {
    background: #b7b7b7;
  }
}
